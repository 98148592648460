<template>
 <router-view></router-view>
</template>
<script>
export default {
  methods: {
  },
  name: 'App',
}
</script>
